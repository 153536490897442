html, body {
  height: 100%;
  margin: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans",sans-serif;
  background: #262626;
  color: #36383B !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {box-shadow: 1px 1px 5px #BFBFBF; }

.maker {cursor: crosshair; }

.container { width: 305px; margin: 0% auto 0 auto; }

.container-fluid {
  height: 100%;
}