.page-logo {
  height: 50px;
  max-height: 50px;
  line-height: 50px;
}

.page-logo > img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.login-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-top: 25px
}

.login-footer .login-social {
  padding-right: 0
}

.login-footer .login-social li {
  display: inline-block;
  list-style: none;
  margin-right: 1em
}

.login-footer .login-social a {
  color: #a9b5be;
  font-size: 18px
}

.login-footer .login-social a:hover {
  color: #337ab7;
  text-decoration: none
}

.login-footer .login-social a:focus {
  color: #a9b5be
}

.login-footer .login-copyright {
  padding-left: 0;
  margin-top: 6px
}

.login-footer .login-copyright>p {
  margin: 0;
  font-size: 13px;
  color: #a9b5be
}